import React, { useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './pages/App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'
import NoMatch from './pages/NoMatch'
import { AppContext } from './context/AppContext'
import Navbar from './components/Navbar'
import WorkSingle from './pages/Work/WorkSingle'
import CareersSingle from './pages/Career/CareerSingle'
import axios from 'axios'
import NewsSingle from './pages/News/NewsSingle'
import { Helmet } from 'react-helmet'

const root = ReactDOM.createRoot(document.getElementById('root'))

export const api = axios.create()
api.defaults.baseURL = process.env.REACT_APP_API_URL + '/api'
const Wrapper = () => {
  const [sidebarHidden, setSidebarHidden] = useState(false)
  const [showNavbar, setShowNavbar] = useState(true)
  const [scrollY, setScrollY] = useState(0)
  const [title, setTitle] = useState(process.env.REACT_APP_TITLE)
  const [canonical, setCanonical] = useState('')
  const [description, setDescription] = useState(process.env.REACT_APP_DESCRIPTION)

  useEffect(() => {
    const _ = () => {
      const scrollPercent = ((window.scrollY) / ((document.documentElement.scrollHeight) -
        window.innerHeight
      )) * 100
      setScrollPercent(scrollPercent)
      setScrollY(window.scrollY)
    }

    window.addEventListener('scroll', _)
  }, [])

  const fn = {
    sidebarHidden: sidebarHidden,
    setSidebarHidden: setSidebarHidden,
    showNavbar: showNavbar,
    setShowNavbar: setShowNavbar,
    title: title,
    setTitle: setTitle,
    description: description,
    setDescription: setDescription,
    canonical: canonical,
    setCanonical: setCanonical,
  }

  const navBarMemo = useMemo(() => <Navbar/>, [])

  const [scrollPercent, setScrollPercent] = useState(0)

  return <>
    <AppContext.Provider value={{ ...fn }}>
      <Helmet>
        <title>{title}</title>
        <meta name={'description'} content={description}/>
        <link rel={'canonical'} href={canonical}/>
      </Helmet>
      <div className={''}>
        {navBarMemo}
        <>
          <div id={'main'} className={'mb-[1s00vh] shadow-sm relative z-20'}>
            <Outlet/>
            <div id="contact"></div>
          </div>
        </>

      </div>
    </AppContext.Provider>
  </>
}
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppContext.Provider value={{}}>
        <Routes>
          <Route path={'/'} element={<Wrapper/>}>
            <Route index element={<App/>}/>
            <Route path={'work'} element={<Navigate to={'/'}/>}/>
            <Route path={'work/:id'} element={<WorkSingle/>}/>
            <Route path={'news'} element={<Navigate to={'/'}/>}/>
            <Route path={'news/:slug'} element={<NewsSingle/>}/>
            <Route path={'careers'} element={<Navigate to={'/'}/>}/>
            <Route path={'careers/:id'} element={<CareersSingle/>}/>
            <Route path="*" element={<NoMatch/>}/>
          </Route>
        </Routes>
      </AppContext.Provider>
    </BrowserRouter>
  </React.StrictMode>
)
reportWebVitals()
